import React, { useState } from "react";
import sec2time from "../../../../utils/Functions";
import { callIcon } from "../../../../utils/Functions";
import { callType } from "../../../../utils/Functions";
import moment from "moment";
// import { ReactComponent as Sms } from "../../../Assets/Icons/Sms.svg";
// import ls from "local-storage";
import CallViaFrame from "../../../CallViaFrame/CallViaFrame";
import { ReactComponent as Ringover } from "../../../../Assets/Icons/Ringover.svg";
import { ReactComponent as Notes } from "../../../../Assets/Icons/Notes.svg";
import ExtraTagsModal from "./components/ExtraTagsModal/ExtraTagsModal";
import NotesModal from "./components/NotesModal/NotesModal";
import parsePhoneNumber from 'libphonenumber-js'
import { ReactComponent as CallINOKVOICEMAIL } from "../../../../Assets/Icons/call-message.svg";
import { ReactComponent as CallINOK } from "../../../../Assets/Icons/Incoming.svg";
import { ReactComponent as CallINKO } from "../../../../Assets/Icons/Missed.svg";
import { ReactComponent as CallOUT } from "../../../../Assets/Icons/Outgoing.svg";
import "../../CallHistory.css";
// import QuickSMSModal from "../../QuickSMSModal/QuickSMSModal";
import ContactInfoModal from "../../../ContactInfoModal/ContactInfoModal";

var fake_token="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJteXJpbmdvdmVyIiwianRpIjoiMzE1Mzk4YTNmNDNiMzNhZTVjNjhkODcyZDEyMWZjNTgwNDczMDU4MWEyY2E3NmYzZGVlZmIxMmRhMGE4NjI3MWVlOTRkZWYyMjlmMjdjNTIiLCJpYXQiOjE2ODA2MjM2NDQuMTE4NzU0LCJuYmYiOjE2ODA2MjM2NDQuMTE4NzU5LCJleHAiOjE2ODA2MjcyNDQuMDk0NjYxLCJzdWIiOiI1ODY4OCIsInNjb3BlcyI6WyJBUElfVjJfQUxMIiwiY2x1c3Rlci5ldSJdLCJzaWQiOiI5ZmY1Y2Y0NGRlMjM3NDYwZjg1ZGYyNWMzY2Q3Njc3OTY4MjQ4ZDZkMzczY2I2YWE4NjI5YWVjMTcxNjYwODc2Yjk4NDNmNmQwMmEyMDliMCIsInVzZXJfaWQiOjU4Njg4LCJ0ZWFtX2lkIjoxLCJ1c2VyX2ZpcnN0bmFtZSI6IktvdXNzYWkiLCJ1c2VyX2xhc3RuYW1lIjoiTWJpcmlraSIsInVzZXJfdXNlcm5hbWUiOiJrb3Vzc2FpIiwidXNlcl9waG90byI6IjYwNmQ2OGVjZDhlNmUuanBnIiwidXNlcl9lbWFpbCI6ImtvdXNzYWkubWJpcmlraUByaW5nb3Zlci5jb20iLCJ0ZWFtX25hbWUiOiJSaW5nb3ZlciBHcm91cCIsInJlZ2lvbiI6IkVVIiwiYXV0aF9kZXRhaWxzIjp7Im1ldGhvZCI6ImNyZWRlbnRpYWwiLCJtZmFfbWV0aG9kIjoibm9uZSIsImZyb21fc2Vzc2lvbiI6dHJ1ZX19.aCQc99mRIbtyy5hquSK39EcaCxRxSHh7Q4bXdKRYtp__LQK_QqoQ2HP_4H1c-OlUUt-KQycVSNpZ9L2Xa5vgd95W5DF6t7-UAhpK-oq49mSSGa7iyORpnZeh77ksvDoKzCIvYLrOhF8WNet42yaRdY8FpNCEkkr2H4ORKhvUR5oXcrd5paHebQ1kX2Yndiei3GHOAiuFpb2ny5MdlS85xSGldejw_mTiW2Nh7u1Xlwfx3kds7DCk_dapGqEW6rLEJxJpH9jeqthM6Tadge6cSy4iVx4sV-JFGVyqXfQOL2FdXGTjYJQyr2xNacwBE_-3jjwXe72atq3cshji2bSfaA";
var fake_user_id="2893289";

function format_full_name(text) {
  let full_name = text.split(" ");
  let initials = full_name[0][0];
  if (text) {
    if (full_name.length >= 2 && full_name[1]) {
      initials += full_name[1][0];
    }
  } else {
    initials = '';
  }

  return initials.toString().toUpperCase();
}


function format_number(number) {
  const phoneNumber = parsePhoneNumber('+' + number)
  var phoneNumberFormat = {};
  if (phoneNumber && phoneNumber.country) {
    if (phoneNumber.isValid()) {
      if (phoneNumber.country === 'FR') {
        phoneNumberFormat = {  //note you don't use = in an object definition
          "country": phoneNumber.country,
          "numberFormat": phoneNumber.formatNational(),
          "formatInternational": false,
        }
        return phoneNumberFormat;
      } else {
        phoneNumberFormat = {  //note you don't use = in an object definition
          "country": phoneNumber.country,
          "numberFormat": " " + phoneNumber.formatInternational(),
          "formatInternational": true,
          "imageContent": "<img src='https://webcdn.ringover.com/app/img/new_flags_svg/" + phoneNumber.country + ".svg' title='flag' />"
        }
        return phoneNumberFormat;
      }
    } else {
      phoneNumberFormat = {  //note you don't use = in an object definition
        "country": phoneNumber.country,
        "numberFormat": " " + phoneNumber.formatInternational(),
        "formatInternational": true,
        "imageContent": "<img src='https://webcdn.ringover.com/app/img/new_flags_svg/" + phoneNumber.country + ".svg' title='flag' />"
      }
      return phoneNumberFormat;
    }
  }

  return false;
}


export default function CallHistoryTab({ obj }) {
  // let initial = obj.contact.initial
  // const [showSMSModal, setShowSMSModal] = useState(false);
  const { contact } = obj;
  const To = obj.anumber ?? null;
  // const From = ls.get("primaryPhone");
  var dateTime = moment(obj.call_start_time).format("DD/MM/YYYY, HH:mm");
  const [showContactModal, setShowContactModal] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [callStatut, setCallStatut] = useState(<CallOUT />);
  useState(() => {
    //https://api-eu.ringover.com/v2/team/team_members

    if (obj && obj.context) {
      if (obj.context == "in") {
        if (obj.last_state == 'VOICEMAIL_ANSWERED') {
          setCallStatut(<CallINOKVOICEMAIL />);
        } else if (['ANSWERED', 'ANNOUNCE_ANSWERED', 'BLIND_TRANSFERED'].includes(obj.last_state)) {
          setCallStatut(<CallINOK />);
        } else if (['VOICEMAIL', 'VOICEMAIL_ANSWERED'].includes(obj.last_state)) {
          setCallStatut(<CallINOKVOICEMAIL />);
        } else {
          setCallStatut(<CallINKO />);
        }
      }
    }
  }, []);


  // const TAGS = obj.tags
  //   ? [
  //       ...obj.tags,
  //       ...obj.tags,
  //       ...obj.tags,
  //       ...obj.tags,
  //       ...obj.tags,
  //       ...obj.tags,
  //       ...obj.tags,
  //     ]
  //   : [];
  const TAGS = obj.tags ?? [];

  return (

    <div className="call-wrapper">
      <div className="outer-crd">
        {showContactModal && (
          <ContactInfoModal
            contact={obj.contact}
            numbers={[{ number: obj.contact_number }]}
            setShowContactModal={setShowContactModal}
          />
        )}

        {obj.comment && obj.comment != "IntraTeam" && obj.context && obj.context == "out" &&

          <>
            <div className="inner-crd-callhistory">
              < div>
                {callStatut}
              </div>
              {/* <div
                className="user-profile-image-box"
                onClick={() => {
                  setShowContactModal(true);
                }}
              >
                {obj?.contact?.picture ? (
                  <img
                    className="user-profile-image"
                    src={obj.contact.picture}
                    alt="dp"
                  />
                ) : (
                  <span className="box-initial">
                    {obj && obj.contact && obj.contact.initial && obj.contact.initial !== "" ? obj.contact.initial
                      : obj && obj.contact && obj.contact.lastname && !obj.contact.initial ? format_full_name(obj.contact.lastname)
                        : "NC"}
                  </span>
                )}
                {contact?.company?.toLowerCase()?.includes("ringover") && (
                  <span
                    className={`ringover-icon-dp ${contact?.picture ? "bg-transparent" : ""
                      }`}
                  >
                    <Ringover />
                  </span>
                )}
              </div> */}

              <h6 className="padding-left">

                <div className="number-text">

                  {obj && obj.clir && obj.clir === true &&
                    <div>
                      Unknown
                    </div>
                  }
                  {obj && obj.onumber && obj.onumber && format_number(obj.onumber) && format_number(obj.onumber).formatInternational &&
                    <div style={{ display: "flex" }}>
                      <div dangerouslySetInnerHTML={{ __html: format_number(obj.onumber).imageContent }}></div>
                      {format_number(obj.onumber).numberFormat}
                    </div>
                  }
                  {obj && obj.onumber && obj.onumber && format_number(obj.onumber) && !format_number(obj.onumber).formatInternational &&
                    <div>
                      {format_number(obj.onumber).numberFormat}
                    </div>
                  }
                </div>
              </h6>
            </div>

            <div className="inner-crdd">
              <div className="title-text" style={{ lineHeight: "21px" }}>
                <>
                  {obj.users && typeof obj.users !== "undefined" ? (
                    <h6 style={{ textTransform: 'capitalize' }} className="title-text"> {obj.users.name.formatted} </h6>
                  ) :
                    <h6 style={{ textTransform: 'capitalize' }} className="title-text"></h6>
                  }
                  <div className="number-text">

                    {obj && obj.clir && obj.clir === true &&
                      <div>
                        Unknown
                      </div>
                    }

                    {obj && obj.bnumber && obj.bnumber && format_number(obj.bnumber) && format_number(obj.bnumber).formatInternational &&
                      <div style={{ display: "flex" }}>
                        <div dangerouslySetInnerHTML={{ __html: format_number(obj.bnumber).imageContent }}></div>
                        {format_number(obj.bnumber).numberFormat}
                      </div>
                    }
                    {obj && obj.bnumber && obj.bnumber && format_number(obj.bnumber) && !format_number(obj.bnumber).formatInternational &&
                      <div>
                        {format_number(obj.bnumber).numberFormat}
                      </div>
                    }


                  </div>
                </>

              </div>

              {/* {console?.log("initial : ",initial)} */}


              <div className="call-notes-tags">
                {obj.note && (
                  <div
                    onMouseOver={() => {
                      setShowNote(true);
                    }}
                    onMouseOut={() => {
                      setShowNote(false);
                    }}
                  >
                    <Notes className="ch-notes-svg" />
                    {showNote && <NotesModal text={obj.note} />}
                  </div>
                )}
                <div className="ch-tags">
                  {TAGS.slice(0, 2).map((tag) => (
                    <span
                      className="ch-tag"
                      style={{
                        border: `1px solid #${tag.color}`,
                        borderRadius: "4px",
                        color: `#${tag.color}`,
                        fontSize: "10px",
                      }}
                    >
                      {tag.name}
                    </span>
                  ))}
                  {TAGS.length > 2 && (
                    <div
                      //
                      className={`more-ch-tags`}
                      style={{
                        color: TAGS[3] ? `#${TAGS[3].color}` : `#f9f9f900`,
                        textDecoration: "underline",
                        textUnderlineOffset: "3px",
                        marginLeft: "2px",
                      }}
                      onClick={() => setShowTagsModal(!showTagsModal)}
                    >
                      +{TAGS.length - 2}
                    </div>
                  )}
                  {showTagsModal && (
                    <ExtraTagsModal
                      tags={TAGS.slice(2)}
                      setShowMoreTags={setShowTagsModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        }
        {obj.context && obj.context != "IntraTeam" && obj.context && obj.context == "in" &&
          <>
            <div className="inner-crd-callhistory">
              < div style={{ paddingTop: "5px", paddingRight: "5px" }}>
                {callStatut}
              </div>
              {/* <div
                className="user-profile-image-box"
                onClick={() => {
                  setShowContactModal(true);
                }}
              >
                {obj?.contact?.picture ? (
                  <img
                    className="user-profile-image"
                    src={obj.contact.picture}
                    alt="dp"
                  />
                ) : (
                  <span className="box-initial">
                    {obj && obj.contact && obj.contact.initial && obj.contact.initial !== "" ? obj.contact.initial
                      : obj && obj.contact && obj.contact.lastname && !obj.contact.initial ? format_full_name(obj.contact.lastname)
                        : "NC"}
                  </span>
                )}
                {contact?.company?.toLowerCase()?.includes("ringover") && (
                  <span
                    className={`ringover-icon-dp ${contact?.picture ? "bg-transparent" : ""
                      }`}
                  >
                    <Ringover />
                  </span>
                )}
              </div> */}

              <h6 className="padding-left">
              {obj.subcdrs && typeof obj.subcdrs !== "undefined" && obj.subcdrs[0].users && typeof obj.subcdrs[0].users !== "undefined" ? (
                  <h6 style={{ textTransform: 'capitalize' }} className="title-text"> {obj.subcdrs[0].users.name.formatted} </h6>
                ) :
                  <h6 style={{ textTransform: 'capitalize' }} className="title-text"></h6>
                }
                <div className="number-text">
                
                {obj.ivrs && typeof obj.ivrs !== "undefined" &&

                  <div style={{background:"#"+obj.ivrs.color, width:"50px", color:"white", fontWeight:"900", textShadow: "rgba(0, 0, 0, 0.15)", textOverflow:"ellipsis", whiteSpace:"nowrap", borderRadius:"12px", overflow:"hidden", minWidth:"100px", maxWidth:"160px", textAlign:"center"}}>
                    {obj.ivrs.name}
                    </div>

                }
                {obj && obj.clir && obj.clir === true &&
                    <div>
                      Unknown
                    </div>
                  }
                  {obj && obj.anumber && obj.anumber && format_number(obj.anumber) && format_number(obj.anumber).formatInternational &&
                    <div style={{ display: "flex" }}>
                      <div dangerouslySetInnerHTML={{ __html: format_number(obj.anumber).imageContent }}></div>
                      {format_number(obj.anumber).numberFormat}
                    </div>
                  }
                  {obj && obj.anumber && obj.anumber && format_number(obj.anumber) && !format_number(obj.anumber).formatInternational &&
                    <div>
                      {format_number(obj.anumber).numberFormat}
                    </div>
                  }
                </div>
              </h6>
            </div>

            <div className="inner-crdd">
              <div className="title-text" style={{ lineHeight: "21px" }}>
                <>
                  {obj.users && typeof obj.users !== "undefined" ? (
                    <h6 style={{ textTransform: 'capitalize' }} className="title-text"> {obj.users.name.formatted} </h6>
                  ) :
                    <h6 style={{ textTransform: 'capitalize' }} className="title-text"></h6>
                  }
                  <div className="number-text">

                  
                    {obj && obj.bnumber && obj.bnumber && format_number(obj.bnumber) && format_number(obj.bnumber).formatInternational &&
                      <div style={{ display: "flex" }}>
                        <div dangerouslySetInnerHTML={{ __html: format_number(obj.bnumber).imageContent }}></div>
                        {format_number(obj.bnumber).numberFormat}
                      </div>
                    }
                    {obj && obj.bnumber && obj.bnumber && format_number(obj.bnumber) && !format_number(obj.bnumber).formatInternational &&
                      <div>
                        {format_number(obj.bnumber).numberFormat}
                      </div>
                    }
                  </div>
                </>

              </div>

              <div className="call-notes-tags">
                {obj.note && (
                  <div
                    onMouseOver={() => {
                      setShowNote(true);
                    }}
                    onMouseOut={() => {
                      setShowNote(false);
                    }}
                  >
                    <Notes className="ch-notes-svg" />
                    {showNote && <NotesModal text={obj.note} />}
                  </div>
                )}
                <div className="ch-tags">
                  {TAGS.slice(0, 2).map((tag) => (
                    <span
                      className="ch-tag"
                      style={{
                        border: `1px solid #${tag.color}`,
                        borderRadius: "4px",
                        color: `#${tag.color}`,
                        fontSize: "10px",
                      }}
                    >
                      {tag.name}
                    </span>
                  ))}
                  {TAGS.length > 2 && (
                    <div
                      //
                      className={`more-ch-tags`}
                      style={{
                        color: TAGS[3] ? `#${TAGS[3].color}` : `#f9f9f900`,
                        textDecoration: "underline",
                        textUnderlineOffset: "3px",
                        marginLeft: "2px",
                      }}
                      onClick={() => setShowTagsModal(!showTagsModal)}
                    >
                      +{TAGS.length - 2}
                    </div>
                  )}
                  {showTagsModal && (
                    <ExtraTagsModal
                      tags={TAGS.slice(2)}
                      setShowMoreTags={setShowTagsModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        }


        {obj.comment && obj.comment == "IntraTeam" && obj.context && obj.context == "out" &&

          <>
            <div className="inner-crd-callhistory">
              < div style={{ paddingTop: "5px", paddingRight: "5px" }}>
                {callStatut}
              </div>
              {/* <div
                className="user-profile-image-box"
                onClick={() => {
                  setShowContactModal(true);
                }}
              >
                {obj?.contact?.picture ? (
                  <img
                    className="user-profile-image"
                    src={obj.contact.picture}
                    alt="dp"
                  />
                ) : (
                  <span className="box-initial">
                    {obj && obj.contact && obj.contact.initial && obj.contact.initial !== "" ? obj.contact.initial
                      : obj && obj.contact && obj.contact.lastname && !obj.contact.initial ? format_full_name(obj.contact.lastname)
                        : "NC"}
                  </span>
                )}
                {contact?.company?.toLowerCase()?.includes("ringover") && (
                  <span
                    className={`ringover-icon-dp ${contact?.picture ? "bg-transparent" : ""
                      }`}
                  >
                    <Ringover />
                  </span>
                )}
              </div> */}

              <h6 className="padding-left">
              <h6 style={{ textTransform: 'capitalize' }} className="title-text">
                {obj?.subcdrs?.[0]?.users?.name?.formatted ?? ''}
              </h6>
                {/* {obj.subcdrs[0].users && typeof obj.subcdrs[0].users !== "undefined" ? (
                  <h6 style={{ textTransform: 'capitalize' }} className="title-text"> {obj.subcdrs[0].users.name.formatted} </h6>
                ) :
                  <h6 style={{ textTransform: 'capitalize' }} className="title-text"></h6>
                } */}
                <div className="number-text">

                {obj && obj.clir && obj.clir === true &&
                    <div>
                      Unknown
                    </div>
                  }
                  {obj?.subcdrs?.[0]?.anumber &&
                    <>
                      {format_number(obj.subcdrs[0].anumber)?.formatInternational &&
                        <div style={{ display: "flex" }}>
                          <div dangerouslySetInnerHTML={{ __html: format_number(obj.subcdrs[0].anumber).imageContent }}></div>
                          {format_number(obj.subcdrs[0].anumber).numberFormat}
                        </div>
                      }
                      {!format_number(obj.subcdrs[0].anumber)?.formatInternational &&
                        <div>{format_number(obj.subcdrs[0].anumber).numberFormat}</div>
                      }
                    </>
                  }
                  {/* {obj && obj.subcdrs[0].anumber && obj.subcdrs[0].anumber && format_number(obj.subcdrs[0].anumber) && format_number(obj.subcdrs[0].anumber).formatInternational &&
                    <div style={{ display: "flex" }}>
                      <div dangerouslySetInnerHTML={{ __html: format_number(obj.subcdrs[0].anumber).imageContent }}></div>
                      {format_number(obj.subcdrs[0].anumber).numberFormat}
                    </div>
                  }
                  {obj && obj.subcdrs[0].anumber && obj.subcdrs[0].anumber && format_number(obj.subcdrs[0].anumber) && !format_number(obj.subcdrs[0].anumber).formatInternational &&
                    <div>
                      {format_number(obj.subcdrs[0].anumber).numberFormat}
                    </div>
                  } */}
                </div>
              </h6>
            </div>

            <div className="inner-crdd">
              <div className="title-text" style={{ lineHeight: "21px" }}>
                <>
                  {obj.users && typeof obj.users !== "undefined" ? (
                    <h6 style={{ textTransform: 'capitalize' }} className="title-text"> {obj.users.name.formatted} </h6>
                  ) :
                    <h6 style={{ textTransform: 'capitalize' }} className="title-text">test</h6>
                  }
                  <div className="number-text">

                  {obj && obj.clir && obj.clir === true &&
                    <div>
                      Unknown
                    </div>
                  }

                    {obj && obj.bnumber && obj.bnumber && format_number(obj.bnumber) && format_number(obj.bnumber).formatInternational &&
                      <div style={{ display: "flex" }}>
                        <div dangerouslySetInnerHTML={{ __html: format_number(obj.bnumber).imageContent }}></div>
                        {format_number(obj.bnumber).numberFormat}
                      </div>
                    }
                    {obj && obj.bnumber && obj.bnumber && format_number(obj.bnumber) && !format_number(obj.bnumber).formatInternational &&
                      <div>
                        {format_number(obj.bnumber).numberFormat}
                      </div>
                    }


                  </div>
                </>

              </div>

              {/* {console?.log("initial : ",initial)} */}


              <div className="call-notes-tags">
                {obj.note && (
                  <div
                    onMouseOver={() => {
                      setShowNote(true);
                    }}
                    onMouseOut={() => {
                      setShowNote(false);
                    }}
                  >
                    <Notes className="ch-notes-svg" />
                    {showNote && <NotesModal text={obj.note} />}
                  </div>
                )}
                <div className="ch-tags">
                  {TAGS.slice(0, 2).map((tag) => (
                    <span
                      className="ch-tag"
                      style={{
                        border: `1px solid #${tag.color}`,
                        borderRadius: "4px",
                        color: `#${tag.color}`,
                        fontSize: "10px",
                      }}
                    >
                      {tag.name}
                    </span>
                  ))}
                  {TAGS.length > 2 && (
                    <div
                      //
                      className={`more-ch-tags`}
                      style={{
                        color: TAGS[3] ? `#${TAGS[3].color}` : `#f9f9f900`,
                        textDecoration: "underline",
                        textUnderlineOffset: "3px",
                        marginLeft: "2px",
                      }}
                      onClick={() => setShowTagsModal(!showTagsModal)}
                    >
                      +{TAGS.length - 2}
                    </div>
                  )}
                  {showTagsModal && (
                    <ExtraTagsModal
                      tags={TAGS.slice(2)}
                      setShowMoreTags={setShowTagsModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        }
        {obj.context && obj.context == "IntraTeam" && obj.context && obj.context == "in" &&
          <>
            <div className="inner-crd-callhistory">
              < div style={{ paddingTop: "5px", paddingRight: "5px" }}>
                {callStatut}
              </div>
              {/* <div
                className="user-profile-image-box"
                onClick={() => {
                  setShowContactModal(true);
                }}
              >
                {obj?.contact?.picture ? (
                  <img
                    className="user-profile-image"
                    src={obj.contact.picture}
                    alt="dp"
                  />
                ) : (
                  <span className="box-initial">
                    {obj && obj.contact && obj.contact.initial && obj.contact.initial !== "" ? obj.contact.initial
                      : obj && obj.contact && obj.contact.lastname && !obj.contact.initial ? format_full_name(obj.contact.lastname)
                        : "NC"}
                  </span>
                )}
                {contact?.company?.toLowerCase()?.includes("ringover") && (
                  <span
                    className={`ringover-icon-dp ${contact?.picture ? "bg-transparent" : ""
                      }`}
                  >
                    <Ringover />
                  </span>
                )}
              </div> */}

              <h6 className="padding-left">
               
              <h6 style={{ textTransform: 'capitalize' }} className="title-text">
              {obj?.subcdrs?.[0]?.users?.name?.formatted ?? '.'}
            </h6>
            <div className="number-text">
              {obj?.subcdrs?.[0]?.anumber === "Unknown" && <div>Unknown</div>}
              {obj?.subcdrs?.[0]?.anumber && format_number(obj.subcdrs[0].anumber)?.formatInternational &&
                <div style={{ display: "flex" }}>
                  <div dangerouslySetInnerHTML={{ __html: format_number(obj.subcdrs[0].anumber).imageContent }}></div>
                  {format_number(obj.subcdrs[0].anumber).numberFormat}
                </div>
              }
              {obj?.subcdrs?.[0]?.anumber && format_number(obj.subcdrs[0].anumber)?.formatInternational === false &&
                <div>{format_number(obj.subcdrs[0].anumber).numberFormat}</div>
              }
            </div>

                {/* {obj.subcdrs[0].users && typeof obj.subcdrs[0].users !== "undefined" ? (
                  <h6 style={{ textTransform: 'capitalize' }} className="title-text"> {obj.subcdrs[0].users.name.formatted} </h6>
                ) :
                  <h6 style={{ textTransform: 'capitalize' }} className="title-text">.</h6>
                }
                <div className="number-text">

                  {obj && obj.subcdrs[0].anumber && obj.subcdrs[0].anumber === "Unknown" &&
                    <div>
                      Unknown
                    </div>
                  }
                  {obj && obj.subcdrs[0].anumber && obj.subcdrs[0].anumber && format_number(obj.subcdrs[0].anumber) && format_number(obj.subcdrs[0].anumber).formatInternational &&
                    <div style={{ display: "flex" }}>
                      <div dangerouslySetInnerHTML={{ __html: format_number(obj.subcdrs[0].anumber).imageContent }}></div>
                      {format_number(obj.subcdrs[0].anumber).numberFormat}
                    </div>
                  }
                  {obj && obj.subcdrs[0].anumber && obj.subcdrs[0].anumber && format_number(obj.subcdrs[0].anumber) && !format_number(obj.subcdrs[0].anumber).formatInternational &&
                    <div>
                      {format_number(obj.subcdrs[0].anumber).numberFormat}
                    </div>
                  }


                </div> */}
              </h6>
            </div>

            <div className="inner-crdd">
              <div className="title-text" style={{ lineHeight: "21px" }}>
                <>
                  {obj.subcdrs[0].users && typeof obj.subcdrs[0].users !== "undefined" ? (
                    <h6 style={{ textTransform: 'capitalize' }} className="title-text"> {obj.subcdrs[0].users.name.formatted} </h6>
                  ) :
                    <h6 style={{ textTransform: 'capitalize' }} className="title-text">.</h6>
                  }
                  <div className="number-text">

                  {obj && obj.clir && obj.clir === true &&
                    <div>
                      Unknown
                    </div>
                  }
                    {obj && obj.bnumber && obj.bnumber && format_number(obj.bnumber) && format_number(obj.bnumber).formatInternational &&
                      <div style={{ display: "flex" }}>
                        <div dangerouslySetInnerHTML={{ __html: format_number(obj.bnumber).imageContent }}></div>
                        {format_number(obj.bnumber).numberFormat}
                      </div>
                    }
                    {obj && obj.bnumber && obj.bnumber && format_number(obj.bnumber) && !format_number(obj.bnumber).formatInternational &&
                      <div>
                        {format_number(obj.bnumber).numberFormat}
                      </div>
                    }
                  </div>
                </>

              </div>

              <div className="call-notes-tags">
                {obj.note && (
                  <div
                    onMouseOver={() => {
                      setShowNote(true);
                    }}
                    onMouseOut={() => {
                      setShowNote(false);
                    }}
                  >
                    <Notes className="ch-notes-svg" />
                    {showNote && <NotesModal text={obj.note} />}
                  </div>
                )}
                <div className="ch-tags">
                  {TAGS.slice(0, 2).map((tag) => (
                    <span
                      className="ch-tag"
                      style={{
                        border: `1px solid #${tag.color}`,
                        borderRadius: "4px",
                        color: `#${tag.color}`,
                        fontSize: "10px",
                      }}
                    >
                      {tag.name}
                    </span>
                  ))}
                  {TAGS.length > 2 && (
                    <div
                      //
                      className={`more-ch-tags`}
                      style={{
                        color: TAGS[3] ? `#${TAGS[3].color}` : `#f9f9f900`,
                        textDecoration: "underline",
                        textUnderlineOffset: "3px",
                        marginLeft: "2px",
                      }}
                      onClick={() => setShowTagsModal(!showTagsModal)}
                    >
                      +{TAGS.length - 2}
                    </div>
                  )}
                  {showTagsModal && (
                    <ExtraTagsModal
                      tags={TAGS.slice(2)}
                      setShowMoreTags={setShowTagsModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        }



        <div className="inner-crd-time text-right">
          <CallViaFrame to={To} styles={{ marginRight: "15px" }} />
          <div>
            <h6> {dateTime}</h6>
            <p style={{ color: "#2081E2" }}> {sec2time(obj?.incall_duration || 0)}s</p>
          </div>
        </div>
      </div>
    </div>
  );
}
